export function singularize(word: string) {
  const endings: Record<string, string> = {
    ves: 'fe',
    ies: 'y',
    i: 'us',
    zes: 'ze',
    ses: 's',
    es: 'e',
    s: '',
  };
  return word.replace(
    new RegExp(`(${Object.keys(endings).join('|')})$`),
    (r: string) => endings[r],
  );
}

export function isSingular(word: string) {
  const exceptions = ['media'];
  if (exceptions.includes(word)) {
    return undefined;
  }
  return singularize(word) === word;
}
